<template>
    <v-container>
    <v-app-bar class="hidden-md-and-down" elevation="0" app color="grey lighten-5 px-4">
        <!-- <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> -->
        <v-img
            class="mr-md-3"
            max-height="60"
            max-width="120"
            src="/logo.svg"
        ></v-img>
        
        <v-tabs v-model="tab" class="ml-md-3" color="grey darken-3" slider-color="yellow darken-3">
            <v-tab 
                exact
                v-for="n in links" 
                :key="n.name" 
                :to="{ name: n.routeName }"
                @click="tab = n.link"
                >
                {{ n.name }}
            </v-tab>
            <v-spacer></v-spacer>
            <div class="d-flex" v-if="currentUser == null">
              <v-tab
                exact-path
                @click="tab = '/login'"
                :to="{ name: 'Login' }"
              >
                เข้าสู่ระบบ
              </v-tab>
              <v-tab
                class="v-btn v-btn--outlined v-btn--rounded theme--light v-size--default yellow--text text--darken-3 my-auto"
                tag="button"
                exact-path
                @click="tab = '/register'"
                :to="{ name: 'Register' }"
              >
                สมัครสมาชิก
              </v-tab>
            </div>
            <div class="d-flex" v-else>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-tab v-bind="attrs" v-on="on">
                    <v-icon>
                      mdi-account
                    </v-icon>
                    {{ nameText }}
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </v-tab>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in menuList"
                    :key="index"
                    link
                    @click="goTo(item.link)"
                  >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="logout">
                    <v-list-item-title>ออกจากระบบ</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mt-2" v-bind="attrs" v-on="on">
                    <v-icon>mdi-bell-outline</v-icon>
                    <v-badge
                      color="red"
                      offset-x="10"
                      offset-y="18"
                      :value="unreadMessage.length != 0"
                      :content="unreadMessage.length"
                    >
                    </v-badge>
                  </v-btn>
                </template>
                <v-list v-if="myNotifications.length > 0" dense class="pa-0">
                  <v-list-item
                    v-for="(item, index) in myNotifications"
                    :key="index"
                    link
                    dense
                    @click="goToDetails(item)"
                    :style="!item.isRead ? 'background-color: #FFFDE7' : ''"
                  >
                    <v-list-item-title class="grey--text text--darken-4">{{ item.message }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            
        </v-tabs>
        
    </v-app-bar>
    <v-app-bar class="hidden-lg-and-up" elevation="0" app color="grey lighten-5 px-4">
        <v-img
            class="ml-n5"
            max-height="60"
            max-width="120"
            src="/logo.svg"
        ></v-img>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
        app
        v-model="drawer"
        temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class=""
        >
          <div class="px-2 mb-2" style="width: 100%">
            <v-img
                class="mx-auto"
                max-width="100"
                src="/logo.svg"
            ></v-img>
          </div>
          <v-divider class="my-2"/>

        <v-subheader><label class="text-body-2 font-weight-bold">เมนูหลัก</label></v-subheader>
          <v-list-item v-for="(link, index) of links" :key="index" exact-path :to="{ name: link.routeName}">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>

          <v-divider class="my-2"/>
          <v-subheader><label class="text-body-2 font-weight-bold">ผู้ใช้งาน</label></v-subheader>
          <div v-if="currentUser == null">
            <v-list-item exact-path :to="{ name: 'Login' }">
              <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-title>เข้าสู่ระบบ</v-list-item-title>
            </v-list-item>

            <v-list-item exact-path :to="{ name: 'Register' }">
              <v-list-item-icon>
                <v-icon>mdi-new-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>สมัครสมาชิก</v-list-item-title>
            </v-list-item>
          </div>
          <div class="mb-10" v-else>
            <v-list-group
              :value="false"
              prepend-icon="mdi-account"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ nameText }}</v-list-item-title>
              </template>
              <v-list-item exact-path :to="{ name: 'MyOrder' }">
                <v-list-item-icon>
                  <v-icon class="ml-3">mdi-clipboard-list-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>รายการของฉัน</v-list-item-title>
              </v-list-item>
              <v-list-item exact-path :to="{ name: 'MyProfile' }">
                <v-list-item-icon>
                  <v-icon class="ml-3">mdi-card-account-details-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>แก้ไขข้อมูลส่วนตัว</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <v-icon class="ml-3">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>ออกจากระบบ</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
          

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-vars */
import { getMyProfile, getMyNotification, checkInMyBell } from '../models/customer';
export default {
    data: () => ({
      tab: '',
      drawer: false,
      group: null,
      links: [
        { routeName: 'Home', icon : 'mdi-home', name: 'หน้าหลัก', link: '/' },
        { routeName: 'Services', icon: 'mdi-order-bool-descending', name: 'บริการของเรา', link: '/services' },
        { routeName: 'About', icon: 'mdi-information', name: 'เกี่ยวกับเรา', link: '/about' },
        { routeName: 'AnnouncementHome', icon: 'mdi-bullhorn', name: 'ข่าวประชาสัมพันธ์', link: '/announcement/home' },
        { routeName: 'Contact', icon: 'mdi-contacts', name: 'ติดต่อเรา', link: '/contact' },
        { routeName: 'Status', icon: 'mdi-list-status', name: 'เช็คสถานะสินค้า', link: '/status' },
        { routeName: 'OrderHome', icon: 'mdi-truck-delivery-outline', name: 'ส่งสินค้า', link: '/order/home' }
      ],
      menuList: [
        { name: 'รายการของฉัน', link: { name: 'MyOrder' } },
        { name: 'แก้ไขข้อมูลส่วนตัว', link: { name: 'MyProfile' } }
      ],
      currentUser: null
    }),
    methods: {
      goTo(path){
        this.$router.push(path).catch(() => {});
      },
      logout(){
        localStorage.clear();
        window.location = '/';
      },
      async getMyNotification(){
        this.myNotification = [];
        const response = await getMyNotification();
        this.$store.dispatch('setMyNotifications', response.data.customerNotifications);
      },
      async goToDetails(notification){
        await checkInMyBell(notification.id);
        this.$store.dispatch('updateAfterClick', notification);
        this.$router.push({ name: 'OrderDetails', params: { id: notification.jobId } }).catch(() => {});
      }
    },
    async mounted(){
      /** Because of appbar don't know what exacly root path ( for rendor bottom border tab) */
      this.tab = '/' + this.$route.path.split('/')[1];
      if(localStorage.getItem('jwt')){
        this.getMyNotification();
        const response = await getMyProfile();
        this.currentUser = response.data;
      }
    },
    computed: {
      nameText(){
        if(this.currentUser){
          const name = this.currentUser.name;
          return name.length > 20 ? name.substring(0, 20) : name;
        }else{
          return '';
        }
      },
      myNotifications(){
        return this.$store.state.myNotifications;
      },
      unreadMessage(){
        return this.$store.state.unreadMessage;
      }
    },
    watch: {
    }
};
</script>