<template>
    <v-app id="inspire">
    <Appbar/>
    <v-main class="grey lighten-3">
        <router-view class="main-div pb-10" style="background:#1D1B19 url('/images/background.png') 0% 0% repeat padding-box;"/>
    </v-main>
    <Footer v-resize="onResize" v-if="isShowFooter && dymamicShowFooter"/>
    </v-app>
</template>

<script>
import Appbar from '../components/Appbar.vue';
import Footer from '../components/Footer.vue';

export default {
    data: () => ({
        dymamicShowFooter: true,
        expanded: false
    }),
    components: {
        Appbar,
        Footer
    },
    methods: {
        onResize () {
            // const x = window.innerWidth;
            // if(x < 600){
            //     this.expanded = true;
            // }else{
            //     this.expanded = false;
            // }
        }
    },
    computed: {
        isShowFooter(){
            return -1 == ['Login', 'Register'].indexOf(this.$route.name);
        }
    },
    watch: {
        '$route.name'(value){
            console.log(value);
        },
        expanded(){ // fix footer not dynamic fit on window size;
            this.dymamicShowFooter = false;
            setTimeout(() => {
                this.dymamicShowFooter = true;
            }, 100);
        }
    }
};
</script>

<style>
    .main-div{
        /* background-repeat: repeat; */
    }
</style>