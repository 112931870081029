<template>
    <v-footer app absolute color="yellow darken-3" v-resize="onResize" :height="!isShowMenu ? '700px' : '300px'">
        <v-container class="my-3">
            <v-row>
                <v-col cols="4">
                    <v-img class="" width="120" src="/logo_black.svg">
                    </v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="4">
                    <div class="d-flex justify-space-between flex-column">
                        <label class="">66/7-9 ซอยสุขาภิบาล 2 ซอย 31 แขวงดอกไม้ เขตประเวศ กรุงเทพมหานคร 10250</label>
                        <label class="">Copyright © 2021 Movevai co, ltd.</label>
                        <label class="">Version. 1.0.0 (1) </label>
                    </div>
                </v-col>
                <v-col class="border-left" cols="12" sm="3">
                    <v-btn class="d-block" height="28" text>หน้าแรก</v-btn>
                    <v-btn class="d-block" height="28" text>เกี่ยวกับเรา</v-btn>
                    <v-btn class="d-block" height="28" text>บริการของเรา</v-btn>
                    <v-btn class="d-block" height="28" text>ข่าวสาร</v-btn>
                    <v-btn class="d-block" height="28" text>เซ็คสถานะ</v-btn>
                    <v-btn class="d-block" height="28" text>ติดต่อเรา</v-btn>
                </v-col>
                <v-col class="border-left" cols="12" sm="3">
                    <label class="d-block mb-1">ติดต่อเรา</label>
                    <label class="d-block mb-1">อีเมลล์ movevai.th@gmail.com</label>
                    <label class="d-block mb-1">
                        <v-icon size="16">mdi-phone</v-icon> 02-613-4780
                    </label>
                    <label class="d-block mb-1">
                        <v-icon size="16">mdi-phone</v-icon> 02-613-4782
                    </label>
                    <label class="d-block mb-1">
                        <v-icon size="16">mdi-phone</v-icon> 02-613-0882
                    </label>
                    <div class="line-it-button" data-lang="th" data-type="friend" data-env="REAL" data-count="true"
                        data-lineId="@thespykid1412" data-home="true" style="display: none;"></div>
                </v-col>
                <v-col cols="6" sm="2">
                    <v-img width="70%" src="/images/qr.png"></v-img>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>

export default {
    data: () => ({
        isShowMenu: true
    }),
    methods: {
        onResize () {
            this.isShowMenu = window.innerWidth > 600;
        }
    }
};
</script>

<style scoped>
    .border-left{
        border-left: 1px solid #666666;
    }
</style>